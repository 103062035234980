<template>
    <div>
        <SimpleNotification ref="notification" />
        <img class="logo" src="/river-inmersivo.png" />
        <div class="container">
            <div v-show="showQrCode">
                <div id="qrScannerContainer">

                    <button id="closeQrScanner" @click="showQrCode = !showQrCode">Cerrar</button>
                    <div>
                        <qrcode-stream v-if="loadQrScanner" :track="onTrack" @camera-on="onCamInit"
                            :constraints="selectedConstraints">
                            <div class="square-overlay"></div>
                        </qrcode-stream>
                    </div>
                    <select v-model="selectedConstraints">
                        <option v-for="option in constraintOptions" :key="option.label" :value="option.constraints">
                            {{ option.label }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="draggable-list">
                <draggable v-model="listaJugadores" @start="isDragging = true" @end="onDragEnd"
                    item-key="listaJugadores" group="jugadores">
                    <template #item="{ element, index }">
                        <div class="list-group-item" :data-id="element.id">
                            <div class="list-index">{{ index + 1 }}</div>
                            <div class="list-name" v-if="!element.empty">
                                <div class="avatar">
                                    <img v-if="element.avatar" :src="element.avatar" alt="avatar">
                                </div>
                                <div class="name">
                                    {{ element.username }}
                                </div>
                            </div>
                        </div>
                    </template>
                </draggable>
            </div>
            <draggable class="draggable-list draggable-remove-list" :class="{ dragging: isDragging }"
                v-model="listaBorrar" item-key="listaBorrar" @add="onRemoveAdded" group="jugadores">
                <template #item="{ e }">
                    <div style="display: none;">{{ e }}</div>
                </template>
            </draggable>

            <div id="button-section">
                <button :disabled="DeshabilitarAgregarJugadores" @click="showQrCode = !showQrCode">Escanear QR</button>
                <button :disabled="DeshabilitarAgregarJugadores" @click="AddGuestUser">Agregar Invitado</button>

                <button :disabled="DeshabilitarComenzarJuego" @click="ComenzarJuego">Comenzar Juego</button>
            </div>
            <!-- <div>
                <p>Estado Juego: {{ gameState }}</p>
            </div> -->

            <!-- Lista jugadores de juego en curso -->
            <div v-if="juegoComenzado && listaJugadoresActual.length">
                <h3>EN JUEGO</h3>
                <div class="listaJugadoresActual">
                    <div v-for="j in listaJugadoresActual" :key="j.id">
                        <img v-if="j.avatar" :src="j.avatar" alt="avatar">
                        <div>{{ j.username }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import draggable from 'vuedraggable'
import SimpleNotification from './SimpleNotification.vue'
const NotificationAudio = require('@/assets/sounds/message-02.mp3');
const defaultConstraintOptions = [
    { label: 'rear camera', constraints: { facingMode: 'environment' } },
    { label: 'front camera', constraints: { facingMode: 'user' } }
]

export default {
    components: {
        QrcodeStream,
        draggable,
        SimpleNotification,
    },
    props: {
        socket: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            notificationSound: null,
            showQrCode: false,
            camera: null,
            cameras: [],
            constraints: {},
            loadQrScanner: false,
            maxJugadores: 6,
            listaJugadores: [],
            listaJugadoresActual: [],
            listaBorrar: [{}],
            juegoComenzado: false,
            gameState: "",
            isDragging: false,
            fetchingUser: false,
            currQrCode: null,
            selectedConstraints: { facingMode: 'environment' },
            constraintOptions: defaultConstraintOptions
        }
    },
    methods: {
        onRemoveAdded() {
            const removedElement = this.listaBorrar[0];
            const removedPosition = removedElement.position;
            // Add empty element to the list in removed position
            this.listaJugadores.splice(removedPosition, 0, {
                id: `-${removedPosition}`,
                username: ``,
                empty: true,
                position: removedPosition,
                avatar: null,
                guest: false,
            });
            this.listaBorrar = [];
        },
        async onCamInit() {
            // NOTE: on iOS we can't invoke `enumerateDevices` before the user has given
            // camera access permission. `QrcodeStream` internally takes care of
            // requesting the permissions. The `camera-on` event should guarantee that this
            // has happened.
            const devices = await navigator.mediaDevices.enumerateDevices()
            const videoDevices = devices.filter(({ kind }) => kind === 'videoinput')

            this.constraintOptions = [
                ...defaultConstraintOptions,
                ...videoDevices.map(({ deviceId, label }) => ({
                    label: `${label}`,
                    constraints: { deviceId }
                }))
            ]
        },
        async onTrack(detectedCodes) {
            // This function will be called for each frame
            // Return an array of detected codes you want to track
            if (!this.showQrCode) return;
            if (!detectedCodes.length) return;

            const detectedID = detectedCodes[0]?.rawValue;
            if (this.currQrCode === detectedID) return;
            this.currQrCode = detectedID;
            console.log("detected QR", detectedID)
            // Parse as json
            let data = JSON.parse(detectedID)
            if (data.token && data.token.length > 0) {
                this.FetchUser(data.token);
            }
        },
        async FetchUser(token) {
            if (this.fetchingUser) return;
            this.fetchingUser = true;
            try {
                // fetch user data using token in header
                const res = await fetch(process.env.VUE_APP_API_URL + "/usuarios", {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })

                if (res.status === 200) {
                    const user = await res.json();
                    console.log("user", user)
                    this.AddUser(user.username, user.id, user.avatar);
                } else {
                    this.$refs.notification.show('Usuario no encontrado', 'error', 3000);
                }
            } catch (e) {
                console.error(e)
            } finally {
                this.showQrCode = false;
                this.fetchingUser = false;
            }
        },
        async selectCamera() {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                this.cameras = devices.filter(device => device.kind === 'videoinput');

                // Prioritize front-facing camera on mobile devices
                const frontCamera = this.cameras.find(cam =>
                    cam.label.toLowerCase().includes('front') ||
                    cam.label.toLowerCase().includes('selfie')
                );

                if (frontCamera) {
                    // Use front-facing camera if available
                    this.camera = frontCamera.deviceId;
                } else {
                    // Fallback to previous logic for selecting a webcam
                    const webcam = this.cameras.find(cam => cam.label.toLowerCase().includes('webcam'));

                    if (webcam) {
                        this.camera = webcam.deviceId;
                    } else if (this.cameras.length > 0) {
                        // If no specific webcam is found, use the first available camera
                        this.camera = this.cameras[0].deviceId;
                    }
                }

                // Set the constraints based on the selected camera
                this.constraints = {
                    video: {
                        deviceId: this.camera ? { exact: this.camera } : undefined
                    }
                };

                this.loadQrScanner = true;
            } catch (error) {
                console.error('Error accessing media devices:', error);
            }
        },
        onError(error) {
            console.error('Error accessing media devices:', error)
            if (error.name === 'NotAllowedError') {
                // user denied camera access permission
            } else if (error.name === 'NotFoundError') {
                // no suitable camera device installed
            } else if (error.name === 'NotSupportedError') {
                // page is not served over HTTPS (or localhost)
            } else if (error.name === 'NotReadableError') {
                // maybe camera is already in use
            } else if (error.name === 'OverconstrainedError') {
                // did you request the front camera although there is none?
            } else if (error.name === 'StreamApiNotSupportedError') {
                // browser seems to be lacking features
            }
        },
        AddUser(username, id, avatar = null, guest = false, emit = true) {
            // chequeo que no haya otro con mismo id
            let index = this.listaJugadores.findIndex(j => j.id === id);
            if (index >= 0) {
                this.$refs.notification.show(`Este jugador ya está en fila`, 'error', 3000);
                return;
            }

            let emptyIndex = this.listaJugadores.findIndex(j => j.empty);
            if (emptyIndex >= 0) {
                this.listaJugadores[emptyIndex].username = username;
                this.listaJugadores[emptyIndex].id = id;
                this.listaJugadores[emptyIndex].empty = false;
                this.listaJugadores[emptyIndex].position = emptyIndex;
                this.listaJugadores[emptyIndex].avatar = avatar;
                this.listaJugadores[emptyIndex].guest = guest;
            }

            if (emit) {
                this.EmitPlayerList();
            }
            this.$refs.notification.show(`Hola ${username}!`, 'success', 3000);
            this.notificationSound.volume = 0.3;
            this.notificationSound.play()
        },
        AddGuestUser() {
            const id = Math.floor(Math.random() * 999).toString(); //Math.random().toString(36).substring(3)
            const randomName = "Invitado " + id;
            const avatar = process.env.VUE_APP_BACKEND_HOST + "/guest.png";
            this.AddUser(randomName, id, avatar, true);
        },
        EmitPlayerList() {
            this.AdjustPlayerOrder();
            const players = this.listaJugadores.filter(j => !j.empty)
            this.socket.emit('PlayerList', players);
            // console.log("EmitPlayerList", players)
        },
        RemovePlayer(id) {
            let index = this.listaJugadores.findIndex(j => j.id === id);
            if (index >= 0) {
                this.listaJugadores[index].username = "";
                this.listaJugadores[index].id = -index;
                this.listaJugadores[index].empty = true;
                this.listaJugadores[index].avatar = null;
                this.listaJugadores[index].guest = false;
            }
            this.EmitPlayerList();
        },
        ComenzarJuego() {
            // TODO enviar socket
            // console.log("ComenzarJuego")
            this.juegoComenzado = true;
            this.socket.emit('StartGame');
            // clone lista jugadores
            this.listaJugadoresActual = JSON.parse(JSON.stringify(this.listaJugadores));
            this.listaJugadoresActual = this.listaJugadoresActual.filter(j => !j.empty);
            // clear lista jugadores
            this.listaJugadores.forEach(j => {
                j.username = "";
                j.id = -j.position;
                j.empty = true;
                j.avatar = null;
                j.guest = false;
            });
        },
        onDragEnd() {
            // console.log("onDragEnd", e)
            // const playerId = e.dataset.id;
            // // const oldIndex = e.oldIndex;
            // const newIndex = e.newIndex;
            // TODO enviar toda la lista de nuevo
            // update position
            this.isDragging = false;
            this.EmitPlayerList();
        },
        AdjustPlayerOrder() {
            for (let i = 0; i < this.listaJugadores.length; i++) {
                this.listaJugadores[i].position = i;
            }
        },
        GameFinished() {
            // console.log("GameFinished")
            this.juegoComenzado = false;
            setTimeout(() => {
                if (this.listaJugadores.filter(j => !j.empty).length == 0) return;
                this.EmitPlayerList();
            }, 1000);
        }
    },
    computed: {
        maximoJugadoresAlcanzado() {
            return this.listaJugadores.filter(j => !j.empty).length >= this.maxJugadores;
        },
        DeshabilitarComenzarJuego() {
            if (this.listaJugadores.filter(j => !j.empty).length == 0) return true;
            if (this.juegoComenzado) return true;
            return false;
        },
        DeshabilitarAgregarJugadores() {
            return this.maximoJugadoresAlcanzado;
        }
    },
    mounted() {
        this.selectCamera();
        // Lleno el array de jugadores
        for (let i = 0; i < this.maxJugadores; i++) {
            this.listaJugadores.push({
                id: `-${i}`,
                username: ``,
                empty: true,
                position: i,
                avatar: null,
                guest: false,
            })
        }

        this.socket.on("StartGame", () => {
            // console.log("Received StartGame")
            this.juegoComenzado = true;
        });
        this.socket.on("GameFinished", () => {
            // console.log("GameFinished")
            this.GameFinished();
        });
        this.socket.on("GameState", (state) => {
            // console.log("GameState", state)
            this.gameState = state;
        });
        this.socket.on("PlayerList", (players) => {
            // console.log("PlayerList", players)
            if (!players.length) return
            // primero vacio la lista
            for (let i = 0; i < this.listaJugadores.length; i++) {
                this.listaJugadores[i].username = "";
                this.listaJugadores[i].id = -i;
                this.listaJugadores[i].empty = true;
                this.listaJugadores[i].avatar = null;
                this.listaJugadores[i].guest = false;
            }
            // luego la lleno con los nuevos jugadores
            players.forEach(p => {
                // console.log("PlayerList", p.position, p)
                this.listaJugadores[p.position] = p;
            });
        });

        this.socket.on("MaxPlayers", (maxPlayers) => {
            // console.log("MaxPlayers", maxPlayers)
            this.maxJugadores = maxPlayers;
            this.listaJugadores.splice(0, this.maxJugadores - 1);
            if (this.listaJugadores.length < maxPlayers) {
                for (let i = this.listaJugadores.length - 1; i < maxPlayers - 1; i++) {
                    this.listaJugadores.push({
                        id: `-${i}`,
                        username: ``,
                        empty: true,
                        position: i,
                        avatar: null,
                        guest: false,
                    })
                }
            }
        });

        this.socket.emit("RequestAllData")
        this.notificationSound = new Audio(NotificationAudio);
    }
}
</script>

<style scoped>
@import url('../fonts/stylesheet.css');


#qrScannerContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9999;
}

#closeQrScanner {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    z-index: 1000;
}

.draggable-list {
    width: 100%;
    padding: 10px;
    border: 1px solid rgb(231, 231, 231);
    border-radius: 5px;
    margin-bottom: 20px;
    overflow-y: auto;
    background: #212129;

    .desactivado {
        pointer-events: none;
        opacity: 0.4;
    }
}

.draggable-remove-list {
    opacity: 0;
    border: 1px solid #dc362e;
    background-color: #f8d4d3;
    position: relative;
    /* min-height: 50px; */
    overflow: hidden;

    &::before {
        content: "ELIMINAR";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 100px;
        color: #dc362e;
    }

    &.dragging {
        opacity: 1;
    }
}

/* video{
    object-fit: contain !important;
  } */
.list-group-item {
    display: flex;
    height: 70px;
    margin-bottom: 5px;

    .list-index {
        display: inline-block;
        width: 20px;
        text-align: center;
        width: 35px;
        border: 1px solid black;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        /* background-color: white; */
        background-color: #000;
    }

    .list-name {
        display: inline-block;
        flex-grow: 1;
        border: 1px solid white;
        display: flex;
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-right: 10px;
        background-color: #000;

        margin-right: 5px;

        .avatar {
            width: auto;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .name {
            flex-grow: 1;
            text-align: center;
        }
    }
}

.not-draggable {
    cursor: no-drop;
}

#button-section {
    display: flex;
    flex-direction: column;
    width: 250px;
    margin: 0 auto;

    button {
        margin: 10px 0;
        padding: 10px;
        /* background-color: #000000;
        color: white; */
        border: none;
        border-radius: 5px;
        cursor: pointer;
        height: 50px;

        &:disabled {
            background-color: #cccccc;
            color: #666666;
            cursor: not-allowed;
        }
    }
}

.square-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 70%;
    max-width: 300px;
    max-height: 300px;
    aspect-ratio: 1 / 1;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        /* Fondo negro menos el cuadrado */
        /* box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.05);  */
        /* Esquinas borde */
        --b: 5px;
        --c: #ec192e;
        --w: 15%;
        border: var(--b) solid #0000;
        --_g: #0000 90deg, var(--c) 0;
        --_p: var(--w) var(--w) border-box no-repeat;
        background: conic-gradient(from 90deg at top var(--b) left var(--b), var(--_g)) 0 0 / var(--_p), conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 / var(--_p), conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% / var(--_p), conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g)) 100% 100% / var(--_p);
    }
}
select{
    margin-top: 10px;
    /* appearance: none; */
    line-height: inherit;
    color: inherit;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    padding: 0px;
    background: black;
    font-size: .5rem;
    display: none;
}

/* laptop */
@media (min-width: 1024px) {
    select{
        display: block;
    }
}
</style>