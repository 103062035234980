<template>
  
    <img class="logo" src="/river-inmersivo.png" />
    <div class="auth container">
      <SimpleNotification ref="notification" />
      <h2>Login</h2>
      <form @submit.prevent="login">
        <input v-model="username" placeholder="Username" />
        <input v-model="password" type="password" placeholder="Password" />
        <input type="submit" :disabled="isLoggingIn" :value="isLoggingIn ? 'Ingresando...' : 'Ingresar'" />
      </form>
    </div>
  </template>
  
  <script>
  import { io } from "socket.io-client";
  import SimpleNotification from './SimpleNotification.vue'
  
  export default {
    name: 'RiverAuth',
    components: {
      SimpleNotification
    },
    data() {
      return {
        username: '',
        password: '',
        isLoggingIn: false,
      }
    },
    methods: {
      login() {
        var path = window.location.pathname;
        path = path.replace(/^\/|\/$/g, '');
        console.log("Connecting to room", path)
        
        this.isLoggingIn = true;
        const socket = io(process.env.VUE_APP_BACKEND_HOST, {
          auth: { username: this.username, password: this.password, room: path },
          autoConnect: false
        });
  
        socket.connect();
  
        socket.on('connect', () => {
          console.log('Connected to server');
          this.$emit('login-success', socket);
          this.isLoggingIn = false;
          this.$refs.notification.show('Conexión exitosa', 'success', 3000);
        });
  
        socket.on('connect_error', (err) => {
          console.log('Connection error:', err.message);
          this.isLoggingIn = false;
          socket.close();
          this.$refs.notification.show('Error conectando', 'error', 3000);
        });
      }
    }
  }
  </script>
  
  <style scoped>
  img{
    max-width: 200px;
    margin-top: 50px;
  }
  .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
  }
  .error {
    color: red;
  }
  form{
    display: flex;
    flex-direction: column;
    width: 200px;
    input{
      margin-bottom: 10px;
    }
  }
  </style>