<template>
    <template v-if="!validRoom">
      <SelectRoom />
    </template>
    <template v-else>
      <RiverAuth v-if="!isLoggedIn" @login-success="onLoginSuccess"/>
      <RiverControl v-else :socket="socket"/>
    </template>
</template>

<script>
import SelectRoom from './components/SelectRoom.vue';
import RiverAuth from './components/RiverAuth.vue'
import RiverControl from './components/RiverControl.vue'

export default {
  name: 'App',
  components: {
    RiverControl,
    RiverAuth,
    SelectRoom,
  },
  data() {
    return {
      validRoom: false,
      isLoggedIn: false,
      socket: null,
    }
  },
  methods: {
    onLoginSuccess(socket) {
      console.log('Login successful');
      this.socket = socket;
      this.isLoggedIn = true;
    }
  },
  mounted() {
    var path = window.location.pathname;
    path = path.replace(/^\/|\/$/g, '');
    this.validRoom = path.length > 0;
    console.log(this.validRoom)
  }
}
</script>

<style>
@import url('./fonts/stylesheet.css');
@import url('./style.css');
</style>
