<template>
    <Teleport to="body">
      <Transition name="notification">
        <div v-if="isVisible" class="notification" :class="type">
          {{ message }}
        </div>
      </Transition>
    </Teleport>
  </template>
  
  <script>
  export default {
    name: 'SimpleNotification',
    data() {
      return {
        isVisible: false,
        message: '',
        type: 'info',
        duration: 3000
      }
    },
    methods: {
      show(newMessage, newType = 'info', newDuration = 3000) {
        this.message = newMessage;
        this.type = newType;
        this.duration = newDuration;
        this.isVisible = true;
  
        setTimeout(() => {
          this.isVisible = false;
        }, this.duration);
      }
    }
  }
  </script>
  
  <style scoped>
  .notification {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    z-index: 1000;
  }
  
  .info { background-color: #2196F3; }
  .success { background-color: #4CAF50; }
  .warning { background-color: #FFC107; }
  .error { background-color: #F44336; }
  
  .notification-enter-active,
  .notification-leave-active {
    transition: opacity 0.5s, transform 0.5s;
  }
  
  .notification-enter-from,
  .notification-leave-to {
    opacity: 0;
    transform: translateX(-50%) translateY(-20px);
  }
  </style>