<template>
    <div class="center">
        <img src="/river-inmersivo.png">
        <div>
            <!-- <h1>Ingrese al link de acceso</h1> -->
        </div>
    </div>
</template>

<script>

export default {
    name: 'SelectRoom',
    data() {
        return {
            newRoom: '',
            existingRooms: [],
        }
    },
    methods: {
        IngresarSala(to) {
            // redirect a /:room sin router
            location.href = '/' + to;

        }
    },
    mounted: async function(){
        // fetch rooms
        try{
            const response = await fetch(process.env.VUE_APP_BACKEND_HOST + '/api/rooms');
            const data = await response.json();
            console.log(data)
            this.existingRooms = data.rooms;
            console.log(this.existingRooms)
        }catch(err){
            console.error(err);
        }
    }
}
</script>
<style lang="css">
.center{
    img{
        max-width: 100%;
    }
    padding: 20px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1{
        margin-top: 100px;
    }
}
</style>
